import React from "react";
import { PageWrapper } from "~components/Core";


import FooterFive from '~sections/agency/FooterFive'

import ContentOne from '~sections/services/out-sourcing/Content'

import FeatureSection from '~sections/services/out-sourcing/Feature'
import ServicesSectionTwo from '~sections/services/out-sourcing/ServicesTwo'

import ContactSection from "~sections/contact/ContactOne/ContactSection";

import CtaSection from '~sections/it/Cta'

import Services from '~sections/services/out-sourcing/Services'

import Integration from '~sections/services/out-sourcing/Integration'


export default function Project() {
    return (
        <PageWrapper innerPage={true}>

            <ContentOne />
            <Services />
            {/* <FeatureSection />
            <ServicesSectionTwo /> */}
            {/* <ContactSection /> */}

            {/* <Integration /> */}

            <CtaSection />

            <FooterFive />

        </PageWrapper>
    )
}
